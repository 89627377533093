<template>
  <div>
    <div
      class="rounded-lg align-center p-5 "
      style="background-color: #e1eef7"
    >
      <div class="flex justify-between mb-8 px-2">
        <h2 class="text-2xl font-bold text-primary">
          <md-icon class="text-4xl mr-3 text-algal">laptop</md-icon>
          Online Class
        </h2>
        <div class="flex align-center">
          <span class="text-base mr-4 font-semibold">Total Online Classes <span class="mx-3 px-2 py-1 border border-solid rounded-lg font-bold text-algal">{{ total }}</span></span>
          <span class="text-base mr-4 font-semibold">Completed <span class="mx-3 px-2 py-1 border border-solid rounded-lg font-bold text-algal">{{ completed }}</span></span>
          <span class="text-base font-semibold">Remaining <span class="mx-3 px-2 py-1 border border-solid rounded-lg font-bold text-algal">{{ remaining }}</span></span>
        </div>
      </div>

      <LinearProgressBar :progressValue="completion_rate" label="Completion Rate"/>
    </div>

    <div class="my-5 flex justify-between">
      <!-- {{ getRibbonses }} -->
      <CustomSingleSelector 
        placeholder="Select a Class"
        v-model.trim="selectedClassType"
        :list="getRibbonses"
        titleKey="title"
        @input="onSearchByRibbones"
      />

      <ComboSearchBox
          placeholder="Search"
          class="w-84 m-1"
          :results="getSuggestions"
          resultKey="title"
          result-key="header"
          :isMultiIcon="true"
          @submit="submitSearchKeyword" 
          @onFilterByDuration="onFilterByDuration"
          @onResetSearch="onResetSearch"
          @onUpdateSortByNumberOfChapter="onUpdateSortByNumberOfChapter"
      />
    </div>

    <div v-if="loading">
        <Loader />
    </div>
    <div>
      <CarouselGrids :items="getFeaturedClasses" />
    </div>

    <ClassGrids :items="getMoreClasses"/>

    <div class="flex justify-center my-8" v-if="getClassesMeta">
      <Paginate
        bgColor="bg-algal"
        :start="getClassesMeta.current_page"
        :end="getClassesMeta.last_page"
        :total="getClassesMeta.last_page"
        :limit="Number(getClassesMeta.per_page)"
        @on-start="onStart"
        @on-end="onEnd"
      />
    </div>
  </div>
</template>

<script>
import { Paginate } from "@/components";
import Loader from '@/components/atom/loader.vue';
import LinearProgressBar from "@/components/atom/LinearProgressBar.vue";
import CarouselGrids from "@/components/molecule/student/online-class/CarouselGrids.vue"
import ClassGrids from "@/components/molecule/student/online-class/ClassGrids.vue"
import data from "@/data/online-class/data"
import ComboSearchBox from "@/components/atom/ComboSearchBox.vue";
import CustomSingleSelector from "@/components/atom/CustomSingleSelector.vue";
import { query } from "@/utils/queryString";
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  components: {
    CustomSingleSelector,
    ComboSearchBox,
    LinearProgressBar,
    ClassGrids,
    CarouselGrids,
    Paginate,
    Loader
  },
  data() {
    return {
      stOnlineClass: data.stOnlineClass,
      items: [
        {
          id: '1',
          name: 'All Online Class',
        },
        {
          id: '2',
          name: 'Featured Class',
        },
        {
          id: '3',
          name: 'Vocabulary',
        },
        {
          id: '4',
          name: 'Reading',
        }
      ],
      selectedClassType: null,
      loading: false,
      pageLimit: 6,
    }
  },
  computed: {
    ...mapGetters({
			getMoreClasses: "onlineClassStudentPart/getMoreClasses",
			getFeaturedClasses: "onlineClassStudentPart/getFeaturedClasses",
			getSummary: "onlineClassStudentPart/getSummary",
			getSuggestions: "onlineClassStudentPart/getSuggestions",
      getClassesMeta: "onlineClassStudentPart/getClassesMeta",
      getRibbonses: "onlineClass/getRibbonses",
		}),

    total() {
      if(this.getSummary && this.getSummary.total) {
        return this.getSummary.total < 10 ? "0" + this.getSummary.total : this.getSummary.total;
      }

      return "00";
    },
    completed() {
      if(this.getSummary && this.getSummary.completed) {
        return this.getSummary.completed < 10 ? "0" + this.getSummary.completed : this.getSummary.completed;
      }

      return "00";
    },
    remaining() {
      if(this.getSummary && this.getSummary.remaining) {
        return this.getSummary.remaining < 10 ? "0" + this.getSummary.remaining : this.getSummary.remaining;
      }

      return "00";
    },
    completion_rate() {
      if(this.getSummary && this.getSummary.completion_rate) {
        return this.getSummary.completion_rate;
      }

      return 0;
    }
  },
  methods: {
    ...mapMutations({
			setShowSnackbar: "setShowSnackbar",
		}),
    ...mapActions({
      fethAllClass: "onlineClassStudentPart/fethAllClass",
      removeAllData: "onlineClassStudentPart/removeAllData",
      fetchAllOnlineClassRibbons: "onlineClass/fetchAllOnlineClassRibbons",
    }),
    onResetPage() {
      query.remove('page');
      query.set('page', 1);
    },
    onSearchByRibbones(val) {
      this.onResetPage();
      query.remove('properties');
      query.set('properties', val?.id);
      this.getAllClass();
    },
    submitSearchKeyword(data) {
      this.onResetPage();

      let searchKeyword= data ?? '';
      query.set('search', searchKeyword);

      this.getAllClass();
    },
    onFilterByDuration(value){
      if(value) {
        this.onResetPage();

        let hour = Number(value);
        let durationInMilisecond = hour*3600;

        query.remove('max_duration');
        query.set('max_duration', durationInMilisecond);
        this.getAllClass();
      }
    },
    onUpdateSortByNumberOfChapter(val){
      this.onResetPage();
      let no_of_chapter = 0;
      if(val === 'ALL') {
        no_of_chapter = 1000;
      } else if(val === 'SMALL') {
        no_of_chapter = 5;
      } else if(val === 'MEDIUM') {
        no_of_chapter = 10;
      } else if(val === 'LARGE') {
        no_of_chapter = 15;
      }

      query.remove('no_of_chapter');
      query.set('no_of_chapter', no_of_chapter);

      this.getAllClass();
    },
    onResetSearch() {
      this.onResetPage();
      query.remove('search');
      query.remove('max_duration');
      query.remove('no_of_chapter');
      query.remove('properties');
      this.selectedClassType = "",
      this.getAllClass();
    },
    onStart(value) {
      if(value == '') {
        query.remove('page');
      }else {
        query.set('page', value);
      }
      this.getAllClass();
    },
    onEnd(value) {
      if(value == '') {
        query.remove('page');
      }else {
        query.set('page', value);
      }
      this.getAllClass();
    },
    getAllClass() {
      this.loading = true;

      // let query = "?search=&max_duration=360000&limit=10&sort_by=total_modules&sort_order=asc";
      let queryObj = `?${query.get()}&limit=${this.pageLimit}&sort_order=desc`;
      this.fethAllClass(queryObj)
      .then()
      .catch((error) => {
				console.log("error", error);
				this.setShowSnackbar(error);
			})
			.finally(() => this.loading = false);
    }
  },
  created() {
    this.fetchAllOnlineClassRibbons();
    this.getAllClass();
    this.removeAllData();
  }
};
</script>

